<template>
  <div v-if="paniere">
    <h1>Informazioni paniere</h1>

    <pdo-card-layout title="Info generali">
      <v-row>
        <pdo-col-info-view label="Nome" :value="paniere.name"/>
        <pdo-col-info-view label="Data apertura" :value="formatDate(paniere.openDate)" :no-value-text="'Non specificata'"/>
        <pdo-col-info-view label="Data chiusura" :value="formatDate(paniere.deliveryDate)" :no-value-text="'Non specificata'"/>
        <pdo-col-info-view label="Stato" :value="paniere.status === 'opened' ? 'Aperto' : (paniere.status === 'closed' ? 'Chiuso' : 'Ordinato')"/>

        <v-col cols="12">
          <v-btn v-if="paniere.status === 'opened'"
                 :disabled="!enabled"
                 @click="closePaniere"
                 outlined
                 color="warning"
                 class="mt-5"
          >Chiudi paniere</v-btn>
          <v-btn v-if="paniere.status === 'closed'"
                 :disabled="!enabled"
                 @click="order"
                 outlined
                 color="warning"
                 class="mt-5"
          >Ordina</v-btn>
        </v-col>
      </v-row>
    </pdo-card-layout>

    <pdo-card-layout title="Fornitori">
      <v-list>
        <v-list-item-group>
          <v-list-item
              v-for="(supplier, id) in paniere.suppliers"
              :key="id"
              @click="clickSupplierRow(supplier)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="supplier.businessName"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </pdo-card-layout>

    <pdo-card-layout title="Ordini del paniere" no-text-content>
      <v-data-table
          class="row-pointer"
          @click:row="clickOrderRow"
          :items="paniere.orders"
          :headers="ordersDataCardHeaders"
          item-key="id.userId + id.paniereId"
          no-data-text="Non ci sono ordini per questo"
          row-pointer
      />
    </pdo-card-layout>

    <paniere-supplier-details-dialog v-if="paniere" :status="paniere.status" :paniere-id="paniere.id" v-model="supplierToShow"/>
    <paniere-order-details-dialog v-if="paniere" v-model="orderToShow"/>
  </div>
</template>

<script>
import PaniereSupplierDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereSupplierDetailsDialog'
import PaniereOrderDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereOrderDetailsDialog'
import PdoCardLayout from '@/components/ui-utils/PdoCardLayout.vue'
import PdoColInfoView from '@/components/ui-utils/PdoColInfoView.vue'

export default {
  name: 'PaniereDetails',
  components: {
    PdoColInfoView,
    PdoCardLayout,
    PaniereOrderDetailsDialog,
    PaniereSupplierDetailsDialog,
  },
  props: {
    value: Object
  },
  data () {
    return {
      paniere: null,
      supplierToShow: null,
      enabled: true,
      ordersDataCardHeaders: [
        { text: 'Nome', value: 'user.name' },
        { text: 'Email', value: 'user.email' },
        { text: 'Numero di telefono', value: 'user.phoneNumber' },
        { text: 'Zona', value: 'deliveryZone.name' },
        { text: 'Nº prodotti', value: 'orderedProducts' }
      ],
      orderToShow: null,
    }
  },
  mounted () {
    this.loadPaniere()
  },
  methods: {
    close () {
      this.$emit('input', null)
    },
    async loadPaniere () {
      try {
        const paniereId = this.$route.params.paniereId
        const response = await this.$http.get('v1/panieri/' + paniereId)
        this.paniere = response.data
      } catch (e) {}
    },
    async clickSupplierRow (supplier) {
      const response = await this.$http.get(`v1/panieri/${this.paniere.id}/${supplier.id}`)
      this.supplierToShow = response.data
    },
    async closePaniere () {
      this.enabled = false
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler chiudere il paniere <b>${this.paniere.name}</b>?`, 'Chiudi')
        await this.$http.patch(`v1/panieri/${this.paniere.id}/close`)
        this.paniere.status = 'closed'
        this.enabled = true
      } catch (e) {
        this.enabled = true
      }
    },
    async order () {
      this.enabled = false
      try {
        await this.$dialog.confirmation.deletion('Sei sicuro do voler ordinare ai fornitori?', 'Ordina')
        await this.$http.patch(`v1/panieri/${this.paniere.id}/order`)
        this.paniere.status = 'ordered'
        this.enabled = true
      } catch (e) {
        this.enabled = true
      }
    },
    async clickOrderRow (order) {
      console.log('order', order)
      const res = await this.$http.get(`v1/orders/${order.id.userId}/${order.id.paniereId}`)
      this.orderToShow = res.data
    }
  }
}
</script>

<style scoped>
</style>
