import { render, staticRenderFns } from "./DistUserDetailsDialog.vue?vue&type=template&id=b4adab18&scoped=true"
import script from "./DistUserDetailsDialog.vue?vue&type=script&lang=js"
export * from "./DistUserDetailsDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4adab18",
  null
  
)

export default component.exports