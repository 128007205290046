<template>
  <div class="mx-10">
    <template>
      <div>
        <v-breadcrumbs
            large
            :items="breadcrumbItem"
        ></v-breadcrumbs>
      </div>
    </template>
    <h1 class="text-center display-2 font-weight" v-if="zone">Utenti di <i>{{ zone.name }}</i></h1>

    <v-card outlined class="mx-auto my-8" max-width="700" v-if="zone">
      <v-card-text>
        <h3>
          {{ zone.pickUpPoint.id ? 'Punto di ritiro: ' : 'Punto di ritiro preselezionato: ' }}
          <!--          <b class="ml-2">{{ zone.pickUpPoint.id ? nameField(zone.pickUpPoint) : nameField(zone.defaultPickUpPoint) }}</b>-->
          <b class="ml-2">{{ zone.pickUpPoint.id ? formatAddress(zone.pickUpPoint) : formatAddress(zone.defaultPickUpPoint) }}</b>
        </h3>
        <div style="width: 100%" class="my-4">
          <div class="mx-auto" style="width: max-content">
            <v-btn class=""
                   small
                   color="primary"
                   :disabled="pickUpBtnDisabled"
                   :loading="pickUpBtnDisabled"
                   v-if="!zone.pickUpPoint.id && zone.defaultPickUpPoint.id"
                   @click="confirmPickUpPoint"
            >
              Conferma
            </v-btn>
            <v-btn class="ml-3" small :disabled="pickUpBtnDisabled" outlined color="primary" @click="changePickUpPoint">Cambia</v-btn>
          </div>
        </div>
        <span>
          La postazione {{ post.code }}
          {{ (post === 'BILANCIA' ? zone.banconeCompleted : zone.bilanciaCompleted) ? 'è già stata' : 'non è ancora stata' }}
          completata per questa zona
        </span>
      </v-card-text>
    </v-card>
    <v-data-table
        :headers="headers"
        :items="users"
        item-key="id"
        class="elevation-1 row-pointer"
        @click:row="rowClick"
    >
      <template v-slot:item.name="{ item }">
        {{ nameField(item.user) }}
      </template>

      <template v-slot:item.business="{ item }">
        {{ businessField(item.user) }}
      </template>

      <template v-slot:item.bilanciaCompleted="{ item }">
        {{ item.bilanciaCompleted ? 'SI' : 'NO' }}
      </template>

      <template v-slot:item.banconeCompleted="{ item }">
        {{ item.banconeCompleted ? 'SI' : 'NO' }}
      </template>
    </v-data-table>
    <v-btn :disabled="confirmBtnDisabled"
           :loading="confirmBtnDisabled"
           @click="confirmZone"
           class="my-5 float-right"
    >Completa la zona e pagamenti della zona
    </v-btn>

    <DistUserDetailsDialog v-model="userToShowId" :post="post" @previousUser="previousUser" @nextUser="nextUser"/>
    <pick-up-point-dialog
        v-if="zone"
        v-model="pickUpPointDialog"
        :zone-id="zone && zone.zoneId"
        :zone-name="zone && zone.name"
        @close="init"
        distribution
    />
  </div>
</template>

<script>
import DistUserDetailsDialog from '@/components/DetailsDialog/DistUserDetailsDialog'
import { mapGetters } from 'vuex'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import PickUpPointDialog from '@/components/ActionDialog/implementations/PickUpPointDialog'

export default {
  name: 'DistListaUtenti',
  components: {
    PickUpPointDialog,
    DistUserDetailsDialog
  },
  mixins: [usersDataCardFields],
  data () {
    return {
      post: null,
      zoneId: null,
      zone: null,
      pickUpPointDialog: false,
      userToShowId: null,
      selectedUserIndex: null,
      pickUpBtnDisabled: false,
      confirmBtnDisabled: false
    }
  },
  computed: {
    ...mapGetters(['users']),
    breadcrumbItem () {
      return [
        {
          text: this.post?.value ?? 'Postazione',
          disabled: false,
          to: '/amministrazione/distribuzione/seleziona-postazione'
        },
        {
          text: 'Zone',
          disabled: false,
          to: '/amministrazione/distribuzione/zone'
        },
        {
          text: 'Utenti',
          disabled: true,
          to: ''
        }
      ]
    },
    headers () {
      return [
        {
          text: 'Nome',
          value: 'name'
        },
        {
          text: 'Azienda',
          value: 'business'
        },
        {
          text: 'Totale prodotti',
          value: 'totalProducts'
        },
        {
          text: 'Completato',
          value: this.post?.code === 'BILANCIA' ? 'bilanciaCompleted' : 'banconeCompleted'
        }
      ]
    }
  },
  watch: {},
  mounted () {
    if (localStorage.getItem('post')) {
      try {
        this.post = JSON.parse(localStorage.getItem('post'))
      } catch (e) {
        localStorage.removeItem('post')
      }
    }
    this.init()
  },
  methods: {
    async init () {
      this.zoneId = this.$route.params.zoneId
      try {
        const zonePromise = this.$http.get(`v1/distribution/zones/${this.zoneId}/details`)

        await this.$store.dispatch('loadDistUsers', {
          zoneId: this.zoneId,
          postCode: this.post.code
        })

        this.zone = (await zonePromise).data
      } catch (e) {
      }
    },
    async rowClick (item, data) {
      try {
        this.selectedUserIndex = data.index
        await this.load(this.selectedUserIndex)
      } catch (e) {
      }
    },
    async confirmPickUpPoint () {
      this.pickUpBtnDisabled = true

      try {
        await this.$http.patch(`/v1/distribution/zones/${this.zoneId}/pick-up-point/${this.zone.defaultPickUpPoint.id}`)
        await this.init()
      } catch (e) {
      }

      this.pickUpBtnDisabled = false
    },
    changePickUpPoint () {
      this.pickUpPointDialog = true
    },
    async previousUser () {
      if (this.selectedUserIndex - 1 < 0) {
        await this.$dialog.info({
          title: 'Attenzione',
          text: 'Non ci sono uteni precedenti!'
        })
        return
      }
      this.selectedUserIndex--
      await this.load(this.selectedUserIndex)
    },
    async nextUser () {
      // Saving promise to perform other actions in parallel.
      // Before the function finish, we await for the end of the init.
      const initPromise = this.init()

      if (this.selectedUserIndex === null) {
        await initPromise
        return
      }

      if (this.selectedUserIndex + 1 >= this.users.length) {
        this.userToShowId = null

        await this.$dialog.info({
          title: 'Attenzione',
          text: 'Hai completato la zona!'
        })

        await initPromise
        return
      }

      this.selectedUserIndex++
      await this.load(this.selectedUserIndex)
      await initPromise
    },
    async load (userIndex) {
      this.userToShowId = this.users[userIndex].user.id
    },
    async confirmZone () {
      try {
        this.confirmBtnDisabled = true
        await this.$http.patch('v1/distribution/zones/' + this.zoneId + '/complete/' + this.post.code)
        this.$router.push('../zone').then()
        await this.$dialog.notify.success('Zona completata con successo!')
      } catch (e) {
      }

      this.confirmBtnDisabled = false
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
