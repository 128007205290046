<template>
  <div class="mx-10 mt-5" v-if="userInfo">
    <div v-if="userInfo" class="text-center">
      <h1>Ciao, {{ userInfo.name }}</h1>
      <p>Profilo</p>
    </div>

    <pdo-card-layout title="Generale" enable-edit-mode @save="saveUserInfo" @reload="getUserInfo"
                     max-width="60rem">
      <v-row>
        <pdo-col-info-view label="Nome" v-model="userInfo.name"/>
        <pdo-col-info-view label="Cognome" v-model="userInfo.surname"/>
        <pdo-col-info-view label="Email" v-model="userInfo.email"/>
        <pdo-col-info-view label="Password" not-editable>
          <v-dialog width="500" v-model="changePasswordDialog">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined>Cambia</v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">Cambio password</v-card-title>
              <v-card-text>
                <text-field
                    v-model="oldPsw"
                    class="mt-2"
                    label="Password attuale"
                    rules="required"
                    :append-icon="showOldPw ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    :type="showOldPw ? 'text' : 'password'"
                    @click:append="showOldPw = !showOldPw"
                    less-margin solo
                />
                <text-field
                    v-model="newPsw"
                    label="Nuova password"
                    rules="required"
                    :append-icon="showNewPw ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    :type="showNewPw ? 'text' : 'password'"
                    @click:append="showNewPw = !showNewPw"
                    less-margin solo
                />
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="changePasswordDialog = false">
                  Annulla
                </v-btn>
                <v-btn color="primary" outlined @click="changePsw">
                  Cambia password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </pdo-col-info-view>
        <pdo-col-info-view label="Cellulare" v-model="userInfo.phoneNumber"/>
        <pdo-col-info-view label="Data di registrazione" :value="formatDate(userInfo.startRegistrationDate)"
                           not-editable/>
      </v-row>
    </pdo-card-layout>

    <pdo-card-layout :title="userInfo.isMember ? 'Stato associato' : 'Diventa socio'" max-width="60rem">
      <div class="text-body-1 px-2">
        <div v-if="userInfo.isMember">
          <p>Sei socio dell'associazione!</p>
          <p v-if="currentYearMembershipPaid">
            <v-icon color="success" class="mr-2">fa-check</v-icon>
            Il pagamento della quota associativa di 15 euro è stato pagato per quest'anno.
          </p>
          <p v-else>
            <v-icon color="warning" class="mr-2">fa-exclamation-triangle</v-icon>
            Il pagamento della quota associativa di 15 euro non risulta ancora stato pagato per quest'anno. Verrà pagato
            automaticamente al prossimo ordine! Vedrai comunque altri avvisi nella pagina del carrello e storico ordini.
          </p>
        </div>

        <div v-else class="text-body-1 px-2">
          Non sei ancora socio dell'associazione, iscriviti ora!
          <br>
          <v-btn color="primary" @click="subscribeAsMember(true)" class="mt-5 mb-2">
            Mi voglio iscrivere!
          </v-btn>
        </div>

        <v-expansion-panels popout flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>
                <v-icon>fa-info-circle</v-icon>
                <span class="ml-2">
                  Quali sono i vantaggi di essere socio?
                </span>
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Il costo della quota associativa annuale è di <b>€ 15,00</b>. Contribuendo con questa modesta somma,
                avremo la possibilità di organizzare vari eventi a cui potrai prendere parte sia come partecipante che
                come volontario. L'iscrizione non implica alcun obbligo, ma ti offre la possibilità di rimanere
                informato sulle attività dell'associazione e di farne parte attivamente.
              </p>

              <p>
                I soci hanno la possibilità di utilizzare il servizio di pagamento <b>SEPA</b> per effettuare un
                unico addebito a fine mese.
              </p>

              <p>
                Stiamo anche lavorando per offrire ulteriori vantaggi esclusivi ai nostri soci come poter usufruire di
                sconti speciali su prodotti di stagione in determinati periodi dell'anno.
              </p>

              <v-expansion-panels popout flat v-if="userInfo.isMember">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Stai cambiando idea?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn color="danger" text @click="subscribeAsMember(false)">
                      Voglio annullare la mia iscrizione come socio
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </pdo-card-layout>

    <pdo-card-layout v-if="userInfo.isMember" title="Dati fiscali" enable-edit-mode max-width="60rem">
      <v-row>
        <pdo-col-info-view label="Codice fiscale" v-model="userInfo.member.fiscalCode"/>
        <pdo-col-info-view label="Tipo documento" v-model="userInfo.member.documentType"/>
        <pdo-col-info-view label="Numero documento" v-model="userInfo.member.documentNumber"/>
        <pdo-col-info-view label="Scadenza documento" :value="formatDate(userInfo.member.documentExpirationDate)">
          <template v-slot:edit-field="{ errors, classes }">
            <date-picker
                :errors="errors"
                :classes="classes"
                v-model="userInfo.member.documentExpirationDate"
                min="1930-01-01"
                :max="new Date().toISOString().substr(0, 10)"
                year-first
                label="Data di nascita"
            />
          </template>
        </pdo-col-info-view>
      </v-row>
    </pdo-card-layout>

    <pdo-card-layout v-if="userInfo.isMember" title="Dati di residenza e nascita" enable-edit-mode max-width="60rem">
      <v-row>
        <pdo-col-info-view label="Indirizzo" v-model="userInfo.member.address"/>
        <pdo-col-info-view label="Città" v-model="userInfo.member.city"/>
        <pdo-col-info-view label="Provincia" v-model="userInfo.member.province"/>
        <pdo-col-info-view label="CAP" v-model="userInfo.member.cap"/>
        <pdo-col-info-view label="Data di nascita" :value="formatDate(userInfo.member.birthDate)">
          <template v-slot:edit-field="{ errors, classes }">
            <date-picker
                :errors="errors"
                :classes="classes"
                v-model="userInfo.member.birthDate"
                min="1930-01-01"
                :max="new Date().toISOString().slice(0, 10)"
                year-first
                label="Data di nascita"
            />
          </template>
        </pdo-col-info-view>
        <pdo-col-info-view label="Città di nascita" v-model="userInfo.member.birthCity"/>
        <pdo-col-info-view label="Provincia di nascita" v-model="userInfo.member.birthProvince"/>
      </v-row>
    </pdo-card-layout>

  </div>
</template>

<script>
import { stringUtils } from '@/mixins/stringUtils'
import { usersDataCardFields } from '@/mixins/usersDataCardFields'
import PdoCardLayout from '@/components/ui-utils/PdoCardLayout.vue'
import PdoColInfoView from '@/components/ui-utils/PdoColInfoView.vue'
import DatePicker from '@/components/Field/DatePicker.vue'

export default {
  name: 'UserInfo',
  components: {
    DatePicker,
    PdoColInfoView,
    PdoCardLayout
  },
  mixins: [stringUtils, usersDataCardFields],
  data () {
    return {
      userInfo: null,
      changePasswordDialog: false,

      oldPsw: null,
      newPsw: null,
      showOldPw: false,
      showNewPw: false
    }
  },
  mounted () {
    this.getUserInfo()
  },
  computed: {
    currentYearMembershipPaid () {
      return this.userInfo.memberLastYearPaid
          && this.userInfo.memberLastYearPaid >= new Date().getFullYear()
    }
  },
  methods: {
    async getUserInfo () {
      const res = await this.$http.get('/v1/users/information')
      this.userInfo = res.data
    },
    async subscribeAsMember (value) {
      if (!value) {
        const res = await this.$dialog.confirm({
          type: 'danger',
          icon: 'fa-exclamation-triangle',
          title: 'Cancellazione iscrizione come socio',
          text: 'Ci dispiace che tu voglia lasciare l\'associazione, ma se hai cambiato idea, puoi farlo in qualsiasi ' +
              'momento.\n\nSei sicuro di voler procedere?',
          actions: [
            { text: 'Annulla', color: 'primary' },
            { text: 'Conferma', color: 'danger' }
          ]
        })
        if (res !== 'Conferma') {
          return
        }
      }
      await this.$http.patch('/v1/users/membership?value=' + value)
      await this.$store.dispatch('renewToken')
      if (value) {
        // avvisa che potrebbe essere reindirizzato per aggiungere ulteriori informazioni
      }
      await this.$router.push('/profile') // redirect if the user should complete the registration (by adding missing info)
      await this.getUserInfo()
    },
    async saveUserInfo () {
      const data = {
        name: this.userInfo.name,
        surname: this.userInfo.surname,
        email: this.userInfo.email,
        phoneNumber: this.userInfo.phoneNumber
      }
      await this.$http.patch('/v1/users/update', data)
      await this.$dialog.notify.success('Modifiche apportate correttamente')
      await this.getUserInfo()
    },
    async saveMemberInfo () {
      await this.$http.patch('/v1/users/update/member', this.userInfo.member)
      await this.$dialog.notify.success('Modifiche apportate correttamente')
      await this.getUserInfo()
    },
    async saveBusinessInfo () {
      await this.$http.patch('/v1/users/update/business', this.userInfo.business)
      await this.$dialog.notify.success('Modifiche apportate correttamente')
      await this.getUserInfo()
    },
    async changePsw () {
      await this.$http.patch('/v1/users/updatePassword', {
        oldPassword: this.oldPsw,
        newPassword: this.newPsw
      })
    },
  }

}

</script>

<style>
</style>
