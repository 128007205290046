<script>
import PdoCardLayout from '@/components/ui-utils/PdoCardLayout.vue'

export default {
  name: 'PaymentAndDelivery',
  components: { PdoCardLayout },
  data () {
    return {
      pyMethods: null,
      zones: [],
      zoneId: null,

      information: null,
      addPmBtnDisabled: false,
      btnsDisabled: false,
      headersCard: [
        {
          text: 'Nome',
          value: 'billingName'
        },
        {
          text: 'Brand',
          value: 'brand'
        },
        {
          text: 'Ultime 4 cifre',
          value: 'last4'
        },
        {
          text: 'Mese di scadenza',
          value: 'expMonth'
        },
        {
          text: 'Anno di scadenza',
          value: 'expYear'
        },
        {
          text: 'Stato',
          value: 'status'
        },
        {
          text: 'Cancella',
          value: 'delete'
        }
      ],
      headersSepa: [
        {
          text: 'Nome',
          value: 'billingName'
        },
        {
          text: 'Ultime 4 cifre',
          value: 'last4'
        },
        {
          text: 'Codice banca',
          value: 'bankCode'
        },
        {
          text: 'Codice branch',
          value: 'branchCode'
        },
        {
          text: 'Stato',
          value: 'status'
        },
        {
          text: 'Cancella',
          value: 'delete'
        }
      ],
    }
  },
  mounted () {
    this.getPyMethods()
    this.getUserZone()
    this.getZones()
  },
  computed: {
    selectedZone () {
      return this.zones.find(z => z.value === this.zoneId)
    }
  },
  methods: {
    async getPyMethods () {
      const res = await this.$http.get('/v1/payment-methods')
      this.pyMethods = res.data
    },
    async saveZone() {
      await this.$http.patch('/v1/users/zone/' + this.zoneId)
      this.$dialog.notify.success('Zona di consegna salvata correttamente')
    },
    async getUserZone() {
      const res = await this.$http.get('/v1/users/zone')
      this.zoneId = res.data
    },
    async getZones () {
      const res = await this.$http('v1/zones')

      this.zones = res.data.map(z => {
        return {
          value: z.id,
          text: z.name,
          pickUpPoint: z.pickUpPoint
        }
      })
    },
    openInGoogleMaps () {
      const { address, city, province } = this.selectedZone.pickUpPoint
      const url = 'https://maps.google.com/maps?q=' + address + ', ' + city + ', ' + province
      window.open(url, '_blank')
    },
    async addPyMethod () {
      this.addPmBtnDisabled = true
      try {
        const res = await this.$http.post('/v1/payment-methods', {
          successUrl: window.location.href,
          cancelUrl: window.location.href
        })
        this.addPmBtnDisabled = false
        window.location.replace(res.data)
      } catch (e) {
        this.addPmBtnDisabled = false
      }
    },
    async setDefaultPm (item) {
      item.defaultBtnLoading = true
      this.btnsDisabled = true
      await this.$http.patch('/v1/payment-methods/default/stripe/' + item.id)
      setTimeout(async () => {
        await this.getPyMethods()
        this.btnsDisabled = false
        item.defaultBtnLoading = false
      }, 5000)
    },
    async deletePm (item) {
      item.deleteBtnLoading = true
      this.btnsDisabled = true
      await this.$http.delete('/v1/payment-methods/' + item.id)
      await this.getPyMethods()
      this.btnsDisabled = false
      item.deleteBtnLoading = false
    }
  }
}
</script>

<template>
  <div class="mx-10 mt-5">
    <div class="text-center">
      <h1>Pagamento e consegna</h1>
      <p>Imposta qui il metodo di pagamento e dove consegnare i tuoi ordini</p>
    </div>

    <pdo-card-layout title="Consegna ordini" max-width="60rem">
      <p class="text-body-1">Seleziona qui il punto di consegna dove preferisci vengano consegnati i tuoi ordini.</p>
      <v-autocomplete
          v-model="zoneId"
          :items="zones"
          label="Seleziona punto di consegna"
          placeholder="Seleziona punto di consegna"
          less-margin solo
          :messages="selectedZone ? ['Indirizzo: ' + selectedZone.pickUpPoint.address + ', ' + selectedZone.pickUpPoint.cap + ' - ' + selectedZone.pickUpPoint.city + ' (' + selectedZone.pickUpPoint.province + ')'] : []"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"/>
            <v-list-item-subtitle>
              {{ item.pickUpPoint.address }}, {{ item.pickUpPoint.cap }} - {{ item.pickUpPoint.city }} ({{ item.pickUpPoint.province }})
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <div class="text-center">
        <v-btn rounded color="primary" class="mb-5 mr-5" @click="saveZone">Salva</v-btn>
      </div>

      <p class="text-body-2" v-if="selectedZone">
        <span class="font-weight-bold">Indirizzo di consegna: </span>
        {{ selectedZone.pickUpPoint.address }}, {{ selectedZone.pickUpPoint.cap }} -
        {{ selectedZone.pickUpPoint.city }} ({{ selectedZone.pickUpPoint.province }})

        <v-btn class="mx-2" text outlined @click="openInGoogleMaps">
          Apri in Google Maps
        </v-btn>
      </p>

      <p class="text-body-2">
        L'indirizzo di consegna potrebbe cambiare in futuro, ma potrai sempre visualizzarlo nel carrello e nel riepilogo dell'ordine.
      </p>

      <p class="text-body-2">
        La modifica di questo punto di ritiro sarà applicato per tutti i prossimi ordini. Se volessi tornare al punto
        di ritiro precedente, dovrai modificare nuovamente questa impostazione dopo il prossimo ordine.
      </p>

      <p class="text-body-2">
        Se vuoi dare la tua disponibilità per essere un punto di ritiro, scrivici a
        <a href="mailto:distribuzione@pdobassogardabio.it">
          distribuzione@pdobassogardabio.it
        </a>
      </p>
    </pdo-card-layout>

    <pdo-card-layout title="Metodi di pagamento" max-width="60rem">
      <v-skeleton-loader v-if="!pyMethods" type="table-thead,table-row"></v-skeleton-loader>
      <div v-else>
        <div class="mb-4">
          <v-btn @click="addPyMethod"
                 rounded
                 class="mb-5"
                 :disabled="addPmBtnDisabled"
                 :loading="addPmBtnDisabled"
          >
            Aggiungi metodo di pagamento
          </v-btn>
        </div>
        <v-card outlined tile>
          <v-card-title>
            Metodo di pagamento predefinito
          </v-card-title>
          <v-card-text>
            <v-row v-if="pyMethods.hasDefaultPm">
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Tipologia
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                Carta
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Titolare
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.billingName }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Brand
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.brand }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Ultime 4
                cifre
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.last4 }}
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Scadenza
              </v-col>
              <v-col v-if="pyMethods.defaultCard" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultCard.expMonth }}/{{ pyMethods.defaultCard.expYear }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Tipologia
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                SEPA
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Titolare
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.billingName }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">Ultime
                4 cifre
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.last4 }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Codice banca
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.bankCode }}
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
                Codice brach
              </v-col>
              <v-col v-if="pyMethods.defaultSepaDebit" cols="7" md="8" class="align-self-center">
                {{ pyMethods.defaultSepaDebit.branchCode }}
              </v-col>

              <v-col cols="5" md="4" class="pr-8 pl-5 text-right text-overline">
              </v-col>
              <v-col cols="7" md="8" class="align-self-center">
                <v-btn :disabled="btnsDisabled"
                       :loading="(pyMethods.defaultCard || pyMethods.defaultSepaDebit).deleteBtnLoading" rounded
                       @click="deletePm(pyMethods.defaultCard || pyMethods.defaultSepaDebit)">Cancella
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                Nessun metodo di pagamento predefinito, selezionane uno!
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined tile class="mt-5">
          <v-card-title>
            Altri metodi di pagamento
          </v-card-title>
          <v-card-text v-if="pyMethods.cards.length || pyMethods.sepaDebits.length">
            <v-data-table
                v-if="pyMethods.cards.length"
                :headers="headersCard"
                :items="pyMethods.cards"
                hide-default-footer
                class="elevation-0"
            >
              <template v-slot:item.status="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.defaultBtnLoading" rounded
                       @click="setDefaultPm(item)">Default
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.deleteBtnLoading" rounded
                       @click="deletePm(item)">Cancella
                </v-btn>
              </template>
            </v-data-table>
            <v-spacer></v-spacer>
            <v-data-table
                v-if="pyMethods.sepaDebits.length"
                :headers="headersSepa"
                :items="pyMethods.sepaDebits"
                hide-default-footer
                class="elevation-0"
            >
              <template v-slot:item.status="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.defaultBtnLoading" rounded
                       @click="setDefaultPm(item)">Default
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn :disabled="btnsDisabled" :loading="item.deleteBtnLoading" rounded
                       @click="deletePm(item)">Cancella
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-else>
            Nessun metodo di pagamento registrato
          </v-card-text>
        </v-card>
      </div>
    </pdo-card-layout>
  </div>
</template>
