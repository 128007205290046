import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css'

import VCol from 'vuetify/lib/components/VGrid/VCol'
import VTextField from 'vuetify/lib/components/VTextField/VTextField'
import VContainer from 'vuetify/lib/components/VGrid/VContainer'
import VTextarea from 'vuetify/lib/components/VTextarea/VTextarea'
import VDivider from 'vuetify/lib/components/VDivider/VDivider'
import VListItemAction from 'vuetify/lib/components/VList/VListItemAction'
import { VChip } from 'vuetify/lib/components/VChip'

Vue.use(Vuetify, {
  components: {
    VCol,
    VTextField,
    VContainer,
    VTextarea,
    VDivider,
    VListItemAction,
    VChip
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#4d9078', // primary: '#5d85d2',
        brown: '#90784D',
        opposite: '#ffffff',
        lighter: '#4aad52', // un colore più chiaro abbinabile al primario, per i pulsanti, ad esempio
        secondary: '#f8f8f8',
        accent: '#FFCA3A',
        warning: '#e68434',
        danger: '#FF5733'
      },
      dark: {}
    }
  }
})
