<template>
  <div>
    <v-container class="my-5">
      <v-btn
          color="primary"
          class="pa-1 mb-5"
          small icon
          @click.stop="backClicked"
      >
        <v-icon small>fas fa-angle-left</v-icon>
      </v-btn>
      <v-btn
          v-if="$route.meta.adminPage"
          class="pa-1 mb-5 float-md-right"
          @click.stop="editClicked"
      >
        Modifica
      </v-btn>

      <div v-if="product">
        <v-row class="mb-12">
          <v-col cols="12" sm="4">
            <v-img
                class="white--text rounded-lg"
                height="300px"
                :src="product.imageUrl || 'https://via.placeholder.com/4032x3024?text=Immagine+non+disponibile'"
            >
            </v-img>
          </v-col>
          <v-col>
            <h3 class="grey--text text--darken-2">{{ product.productType.value }}</h3>
            <h1>{{ product.name }}</h1>
            <p class="mb-3">{{ product.description }}</p>
            <v-divider class="mb-5"></v-divider>
            <p v-if="$route.meta.adminPage"><strong>Unità di misura:</strong> {{ product.measureUnit.value }}</p>
            <p><strong>Modalità d'ordine: </strong>{{ product.orderMode.value }}</p>
            <p v-if="$route.meta.adminPage"><strong>Postazione: </strong>{{ product.post }}</p>
            <p v-if="$route.meta.adminPage"><strong>Moltiplicatore d'ordine: </strong>{{ product.multiplier }}</p>
            <p v-if="$route.meta.adminPage"><strong>Quantità minima: </strong>{{ product.minQuantity }}</p>
            <v-divider v-if="$route.meta.adminPage" class="mb-5"></v-divider>
            <p v-if="$route.meta.adminPage"><strong>Livello di fragilità: </strong>{{ product.weightLevel.value }}</p>
            <v-divider class="mb-4" v-if="$route.meta.adminPage"></v-divider>
            <p v-if="$route.meta.adminPage"><strong>Iva: </strong>{{ product.iva.code }}%</p>
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between align-center pa-2" style="width: 100%">
          <h2 class="mr-3">Fornitori del prodotto</h2>
          <v-btn v-if="$route.meta.adminPage" :disabled="!isSaveActive" color="success" @click="addSupplierProducts">
            Aggiungi
          </v-btn>
        </div>

        <v-container v-if="!$route.meta.adminPage" class="mt-0" style="min-height: 20vh">
          <v-row>
            <v-col v-for="supplierProduct in supplierProducts" :key="supplierProduct.id" cols="12" sm="6" md="4" xl="3">
              <v-card class="mb-3 mx-auto grey lighten-5 elevation-0" max-width="20em">
                <v-card-text class="justify-start world-divided">
                  <p class="my-0">Nome fornitore</p>
                  <p class="black--text my-1 text-h6">{{ supplierProduct.supplierName }}</p>
                  <p class="my-0 mt-5">Prezzo</p>
                  <p class="black--text my-1 text-subtitle-1">
                    <price-per-unit
                        :measure-unit="product.measureUnit"
                        :price="supplierProduct.price"
                        :show-kilos="isPieceWeight(product)"
                    />
                  </p>
                  <p class="my-0 mt-5">Quantità ordinata <span v-if="isPieceWeight(product)">(Nº pezzi)</span></p>
                  <PurchaseField
                      v-model="supplierProduct.purchased"
                      rules="min_value:0"
                      label="Quantità"
                      :id="supplierProduct.id"
                      :min-quantity="product.minQuantity"
                      :multiplier="product.multiplier"
                  />
                  <span v-if="isPieceWeight(product)">Ogni pezzo ha un peso medio di {{ supplierProduct.weight }} kg</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
            v-if="$route.meta.adminPage"
            :headers="headers"
            :items="supplierProducts"
            :items-per-page="5"
            class="elevation-1">

          <template v-slot:item.price="{value}">
            <price-per-unit
                :price="value"
                :measure-unit="product.measureUnit"
                :show-kilos="isPieceWeight(product)"
            />
          </template>
          <template v-slot:item.businessPrice="{value}">
            <price-per-unit
                :price="value"
                :measure-unit="product.measureUnit"
                :show-kilos="isPieceWeight(product)"
            />
          </template>
          <template v-slot:item.supplierPrice="{value}">
            <price-per-unit
                :price="value"
                :measure-unit="product.measureUnit"
                :show-kilos="isPieceWeight(product)"
            />
          </template>
          <template v-slot:item.weight="{value}">
            {{ value }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
              <span
                  v-bind="attrs"
                  v-on="on"
              >
                <small class="black--text">kg</small>
              </span>
              </template>
              <span>Chilogrammo</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn
                color="warning"
                class="pa-0"
                small icon
                @click.stop="editSupplierProducts(item)"
            >
              <v-icon small>fa-edit</v-icon>
            </v-btn>
            <v-btn :disabled="!isDeleteActive"
                   color="red"
                   class="pa-0"
                   small icon
                   @click="deleteItem(item)"
            >
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <supplier-product-dialog
        v-if="$route.meta.adminPage && product"
        v-model="actionModal.show"
        :edit-mode="actionModal.editMode"
        :supplier-product="actionModal.obj"
        :product-id="$route.params.productId"
        :show-weight="isPieceWeight(product)"
        @close="loadSupplierProducts(product.id)"
    />
  </div>
</template>

<script>
import PurchaseField from '@/components/Field/PurchaseField'
import SupplierProductDialog from '@/components/ActionDialog/implementations/SupplierProductDialog'
import PricePerUnit from '@/components/products/PricePerUnit'

export default {
  name: 'ProductDetails',
  components: {
    PricePerUnit,
    SupplierProductDialog,
    PurchaseField
  },
  data () {
    return {
      product: null,
      supplierProducts: [],
      suppliers: [],
      newSupplierProduct: {
        availableQuantity: 0,
        businessPrice: null,
        price: null,
        supplierId: null,
        supplierPrice: null,
        weight: 0
      },
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      isSaveActive: true,
      isDeleteActive: true
    }
  },
  computed: {
    priceComputed: {
      get () {
        return this.newSupplierProduct.price / 100 || ''
      },
      set (newValue) {
        this.newSupplierProduct.price = Math.round(newValue * 100)
      }
    },
    businessPriceComputed: {
      get () {
        return this.newSupplierProduct.businessPrice / 100 || ''
      },
      set (newValue) {
        this.newSupplierProduct.businessPrice = Math.round(newValue * 100)
      }
    },
    suppliersPriceComputed: {
      get () {
        return this.newSupplierProduct.supplierPrice / 100 || ''
      },
      set (newValue) {
        this.newSupplierProduct.supplierPrice = Math.round(newValue * 100)
      }
    },
    headers () {
      const headers = [
        { text: 'Id', value: 'id' },
        { text: 'Nome fornitore', value: 'supplierName' },
        { text: 'Prezzo', value: 'price' },
        { text: 'Prezzo business', value: 'businessPrice' },
        { text: 'Prezzo fornitore', value: 'supplierPrice' }
      ]
      if (this.isPieceWeight(this.product)) {
        headers.push({
          text: 'Peso stimato',
          value: 'weight'
        })
      }
      headers.push({
        text: 'Actions',
        value: 'actions'
      })
      return headers
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const prodId = this.$route.params.productId

      this.loadProduct(prodId)
      this.loadSupplierProducts(prodId)
    },
    async loadProduct (prodId) {
      const res = await this.$http.get('v1/products/' + prodId)
      this.product = res.data
    },
    async loadSupplierProducts (prodId) {
      let res
      if (this.$route.meta.adminPage) {
        res = await this.$http.get('v1/products/' + prodId + '/suppliers/all')
      } else {
        res = await this.$http.get('v1/panieri/products/' + prodId)
      }
      this.supplierProducts = res.data
    },
    async addSupplierProducts () {
      this.actionModal.editMode = false
      this.actionModal.show = true
    },
    async editSupplierProducts (suppProduct) {
      this.actionModal.obj = suppProduct
      this.actionModal.editMode = true
      this.actionModal.show = true
    },
    async deleteItem (item) {
      this.isDeleteActive = false
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler cancellare la fornitura di <b>${item.supplierName}</b>?`)
        await this.$http.delete('/v1/products/suppliers/' + item.id)
          .then(() => {
            this.init()
            this.isDeleteActive = true
          })
      } catch (e) {
        this.isDeleteActive = true
      }
    },
    editClicked () {
      this.$route.meta.edited = true
      this.$router.push('/amministrazione/editproduct/' + this.product.id)
    },
    backClicked () {
      this.$router.go(this.$route.meta.edited ? -3 : -1)
      this.$route.meta.edited = false
    }
  }
}
</script>

<style scoped>
.no-background:hover {
  background-color: transparent !important;
}

p, h3, h1 {
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.world-divided {
  word-break: break-word;
}
</style>
