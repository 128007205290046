import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/views/Login/Login'
import RootCategories from '@/views/RootCategories/RootCategories'
import SubCategories from '@/views/SubCategories/SubCategories'
import CreateProduct from '@/views/Admin/CreateProduct/CreateProduct'
import UserRegistration from '@/views/UserRegistration/UserRegistration'
import ContinueRegistration from '@/views/ContinueRegistration/ContinueRegistration'
import BusinessRegistration from '@/views/ContinueRegistration/BusinessRegistration/BusinessRegistration'
import PersonDataRegistration from '@/views/ContinueRegistration/PersonDataRegistration/PersonDataRegistration'
import FinishRegistration from '@/views/ContinueRegistration/FinishRegistration'
import ProductSuppliers from '@/views/ProductDetails/ProductDetails'
import adminRoutes from '@/router/admin'
import Cart from '@/views/Cart/Cart'
import ConfirmEmail from '@/views/ContinueRegistration/ConfirmEmail'
import ForgotPassword from '@/views/Login/ForgotPassword'
import Profile from '@/views/Profile/Profile'
import Deliveries from '@/views/deliveries/Deliveries'
import DeliveriesDetails from '@/views/deliveries/DeliveriesDetails'
import Payments from '@/views/payments/Payments'
import PaymentsDetails from '@/views/payments/PaymentsDetails'
import Faq from '@/views/Faq/Faq'
import PaymentAndDelivery from '@/views/Profile/PaymentAndDelivery.vue'

Vue.use(VueRouter)

const defaultPath = '/'

const allowedNotAuthenticated = ['/login', '/iscriviti']

function beforeEnter (to, from, next) {
  const isNotAuthenticatedAllowed = allowedNotAuthenticated.includes(to.path)
  if (!store.getters.isLogged) {
    if (isNotAuthenticatedAllowed) {
      next()
    } else {
      redirect(next, '/login', { redirect: to.path })
    }
  } else {
    if (isNotAuthenticatedAllowed) {
      redirect(next, defaultPath)
    } else {
      next()
    }
  }
}

function registrationBeforeEnter (to, from, next) {
  let allowedPath
  let query = {}
  if (!store.getters.isLogged) {
    allowedPath = '/login'
    query = { redirect: to.path }
  } else if (store.getters.registrationComplete) {
    allowedPath = defaultPath
  } else if (store.getters.isMember === null ||
      store.getters.isBusiness === null ||
      (store.getters.isBusiness && store.getters.isIndividualCompany === null)) {
    allowedPath = '/iscriviti/continua'
  } else if (store.getters.isBusiness && !store.getters.businessRegistrationComplete) {
    allowedPath = '/iscriviti/continua/azienda'
  } else if (!store.getters.userRegistrationComplete || (store.getters.isMember && !store.getters.memberRegistrationComplete)) {
    allowedPath = '/iscriviti/continua/persona-fisica'
  } else {
    allowedPath = '/iscriviti/continua/finale'
  }

  if (to.path === allowedPath) {
    next()
  } else {
    redirect(next, allowedPath, query)
  }
}

function redirect (next, path, query) {
  next({
    path: path,
    query,
    replace: true
  })
}

const routes = [
  {
    path: '/',
    component: RootCategories
  },
  {
    path: '/faq',
    component: Faq
  },
  {
    path: '/categorie/:categoryId',
    component: SubCategories
  },
  {
    // do not change because it is used in the email
    path: '/conferma-email',
    component: ConfirmEmail
  },
  {
    path: '/carrello',
    component: Cart,
    beforeEnter
  },
  {
    path: '/consegne',
    component: Deliveries,
    beforeEnter
  },
  {
    path: '/consegne/:id',
    component: DeliveriesDetails,
    beforeEnter
  },
  {
    path: '/pagamenti',
    component: Payments,
    beforeEnter
  },
  {
    path: '/pagamenti/:id',
    component: PaymentsDetails,
    beforeEnter
  },
  {
    path: '/login',
    component: Login,
    beforeEnter
  },
  {
    // do not change because it is used in the email
    path: '/reimposta-password',
    component: ForgotPassword
  },
  {
    path: '/iscriviti',
    component: UserRegistration,
    beforeEnter (to, from, next) {
      if (store.getters.isLogged) {
        redirect(next, '/iscriviti/continua')
      } else {
        next()
      }
    }
  },
  {
    // do not change because it is used in the email
    path: '/iscriviti/continua',
    component: ContinueRegistration,
    beforeEnter: registrationBeforeEnter
  },
  {
    path: '/iscriviti/continua/azienda',
    component: BusinessRegistration,
    beforeEnter: registrationBeforeEnter
  },
  {
    path: '/iscriviti/continua/persona-fisica',
    component: PersonDataRegistration,
    beforeEnter: registrationBeforeEnter
  },
  {
    path: '/iscriviti/continua/finale',
    component: FinishRegistration,
    beforeEnter: registrationBeforeEnter
  },
  {
    path: '/profilo',
    component: Profile,
    beforeEnter: beforeEnter
  },
  {
    path: '/pagamento-consegna',
    component: PaymentAndDelivery,
    beforeEnter: beforeEnter
  },
  {
    path: '/prodotti/:productId',
    component: ProductSuppliers,
    meta: { adminPage: false }
  },
  {
    path: '/crea-prodotto/:categoryId',
    component: CreateProduct,
    beforeEnter (to, from, next) {
      if (!store.getters.isAdmin) {
        redirect(next, defaultPath)
      } else {
        next()
      }
    }
  },
  adminRoutes,
  {
    path: '*',
    redirect: defaultPath
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise(resolve => {
      setTimeout(() =>
        resolve({
          position: savedPosition,
          behavior: 'smooth'
        })
      , 300)
    })
  }
})

export default router
