<template>
  <div class="ma-5">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdminApp'
}
</script>
