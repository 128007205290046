<template>
  <div>
    <DataCard
        title-icon="fas fa-box-open"
        title="Panieri aperti"
        :items="panieriOpen"
        :headers="headers"
        no-data-text="Non ci sono panieri aperti, creane uno!"
        :operations="['create']"
        :create-callback="redirectToCreatePanierePage"
        :action-modal-object.sync="actionModal.obj"
        row-pointer
        is-date
        @click:row="rowClick"
    >
      <template v-slot:item.openDate="{ item }">
        {{ formatDate(item.openDate) }}
      </template>

      <template v-slot:item.deliveryDate="{ item }">
        {{ formatDate(item.deliveryDate) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon :to="'modifica/' + item.id" color="warning">
          <v-icon small>fa-edit</v-icon>
        </v-btn>
      </template>

    </DataCard>

    <DataCard
        title-icon="fas fa-box"
        title="Panieri chiusi"
        :items="panieriClosed"
        :headers="headers"
        no-data-text="Non ci sono panieri!"
        :action-modal-object.sync="actionModal.obj"
        row-pointer
        is-date
        @click:row="rowClick"
    >
      <template v-slot:item.openDate="{ item }">
        {{ formatDate(item.openDate) }}
      </template>

      <template v-slot:item.deliveryDate="{ item }">
        {{ formatDate(item.deliveryDate) }}
      </template>
    </DataCard>

    <DataCard
        title-icon="fas fa-box"
        title="Panieri ordinati"
        :items="panieriOrdered"
        :headers="headers"
        no-data-text="Non ci sono panieri!"
        :action-modal-object.sync="actionModal.obj"
        row-pointer
        is-date
        @click:row="rowClick"
    >
      <template v-slot:item.openDate="{ item }">
        {{ formatDate(item.openDate) }}
      </template>

      <template v-slot:item.deliveryDate="{ item }">
        {{ formatDate(item.deliveryDate) }}
      </template>
    </DataCard>

    <paniere-details-dialog v-model="paniereToShow"/>
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import PaniereDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereDetailsDialog'

export default {
  name: 'Panieri',
  components: { PaniereDetailsDialog, DataCard },
  data () {
    return {
      panieriOpen: [],
      panieriClosed: [],
      panieriOrdered: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Data apertura', value: 'openDate' },
        { text: 'Data di consegna', value: 'deliveryDate' },
        { text: '', value: 'actions', sortable: false }
      ],
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      paniereToShow: null
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    paniereToShow (newVal, oldVal) {
      if (newVal === null) {
        this.init()
      }
    }
  },
  methods: {
    async init () {
      try {
        const responseOpen = await this.$http.get('v1/panieri/status/opened')
        this.panieriOpen = responseOpen.data
        const responseClose = await this.$http.get('v1/panieri/status/closed')
        this.panieriClosed = responseClose.data
        const responseOrderd = await this.$http.get('v1/panieri/status/ordered')
        this.panieriOrdered = responseOrderd.data
      } catch (e) {
      }
    },
    closeActionModal () {
      this.actionModal.show = false
      this.init()
    },
    async rowClick (item) {
      try {
        const response = await this.$http.get('v1/panieri/' + item.id)
        response.data.orders.forEach((order) => {
          order.user.name = this.nameField(order.user)
        })

        this.paniereToShow = response.data
      } catch (e) {}
    },
    redirectToCreatePanierePage () {
      this.$router.push('/amministrazione/panieri/crea')
    }
  }
}
</script>

<style scoped>

</style>
