<template>
  <div class="mx-10">
    <h1 class="text-center display-2 font-weight mt-5 mb-5 ">Inizia una nuova distribuzione</h1>
    <p class="font-weight-light grey--text mt-3">Seleziona i panieri da consegnare.</p>
    <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="panieriClosed"
        item-key="id"
        show-select
        class="elevation-1"
    >
      <template v-slot:item.openDate="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.deliveryDate="{ value }">
        {{ formatDate(value) }}
      </template>
    </v-data-table>
    <div class="d-flex">
      <v-spacer />
      <v-btn :disabled="!enabled"
             :loading="!enabled"
             @click="startDistribuzione"
             class="my-5"
      >Inizia distribuzione</v-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: 'DistStartNew',
  data () {
    return {
      selectedItems: [],
      panieriClosed: [],
      enabled: true,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Data apertura', value: 'openDate' },
        { text: 'Data di consegna', value: 'deliveryDate' }
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getClosedPanieri()
  },
  methods: {
    async getClosedPanieri () {
      try {
        const response = await this.$http.get('v1/panieri/status/ordered')
        this.panieriClosed = response.data
      } catch (e) {

      }
    },
    async startDistribuzione () {
      const panieri = this.selectedItems.map(item => item.id)
      try {
        this.enabled = false
        await this.$http.post('v1/distribution/session', {
          payWithSepa: this.payWithSepa,
          panieriIds: panieri
        })
        this.$router.push('/amministrazione/distribuzione/seleziona-postazione').then()
      } catch (e) {
      }

      this.enabled = true
    }
  }
}
</script>

<style>
</style>
