<template>
  <v-dialog v-model="show"
            v-if="show"
            fullscreen
            transition="dialog-bottom-transition"
            :scrollable="supplierProducts.length > 3">
    <v-card tile>
      <v-toolbar flat color="primary">
        <v-btn icon class="white--text" @click="saveSupplierProducts">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Prodotti fornitore: {{ value.supplierName }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="supplierProducts"
          item-key="id"
          show-select
          :items-per-page.sync="itemsPerPage"
          class="elevation-1"
          @item-selected="onItemSelected"
          @toggle-select-all="onAllItemsSelected"
      >
        <template v-slot:header.data-table-select="{ props, on }">
          <v-tooltip bottom :disabled="!editMode">
            <template v-slot:activator="tooltip">
              <v-simple-checkbox v-if="editMode" disabled/>
              <v-simple-checkbox
                  v-else
                  v-model="props.value"
                  @click.stop="on.input"
                  v-bind="tooltip.attrs"
                  v-on="tooltip.on"
              />
            </template>
            <span>Non abilitato durante la modifica di un paniere</span>
          </v-tooltip>
        </template>

        <template v-slot:item.availableQuantity="{ item }">
          <validation-provider
              rules="required|min_value:0"
              name="quantità"
              v-slot="{ errors }"
          >
            <v-text-field
                v-model.number="item.availableQuantity"
                :disabled="!item.selected"
                :error-messages="errors"
                :append-icon="'fas fa-info-circle'"
                @click:append="showHelpMessage()"
            >
            </v-text-field>
          </validation-provider>
        </template>

        <template v-slot:item.price="{ item }">
          <price-input
              v-model="item.price"
              :disabled="!item.selected"
              label="prezzo"
              hide-label
          />
        </template>

        <template v-slot:item.businessPrice="{ item }">
          <price-input
              v-model="item.businessPrice"
              :disabled="!item.selected"
              label="prezzo business"
              hide-label
          />
        </template>

        <template v-slot:item.supplierPrice="{ item }">
          <price-input
              v-model="item.supplierPrice"
              :disabled="!item.selected"
              label="prezzo fornitore"
              hide-label
          />
        </template>

        <template v-slot:item.weight="{ item }">
          <validation-provider
              v-if="isPieceWeight(item)"
              rules="required|min_value:0"
              name="peso"
              v-slot="{ errors }"
          >
            <v-text-field
                v-model.number="item.weight"
                :disabled="!item.selected"
                :error-messages="errors"
            />
          </validation-provider>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              :disabled="!item.selected"
              outlined
              color="green"
              @click="updateSuppProduct(item)"
          >
            Salva
          </v-btn>
        </template>

      </v-data-table>

      <v-card-actions>
        <v-btn color="primary" text @click="saveSupplierProducts">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PriceInput from '@/components/Field/PriceInput'

export default {
  name: 'SelectSupplierProducts',
  components: {
    PriceInput
  },
  props: {
    value: Object,
    editMode: Boolean,
    paniereToEditId: [Number, String] // String because can be null
  },
  computed: {
    show: {
      get () {
        return this.value !== null
      },
      set (val) {
        this.$emit('input', null)
      }
    }
  },
  data () {
    return {
      supplierProducts: [],
      selectedItems: [],
      itemsPerPage: 10,

      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'productName'
        },
        {
          text: 'Quantità disponibile',
          value: 'availableQuantity'
        },
        {
          text: 'Prezzo di vendita',
          value: 'price'
        },
        {
          text: 'Prezzo business',
          value: 'businessPrice'
        },
        {
          text: 'Prezzo fornitore',
          value: 'supplierPrice'
        },
        {
          text: 'Peso',
          value: 'weight'
        },
        {
          text: 'Azioni',
          value: 'actions',
          sortable: false,
          hide: !this.editMode
        }
      ].filter(h => !h.hide)
    }
  },
  watch: {
    value (newValue) {
      if (newValue) this.getData()
    }
  },
  mounted () {
    if (this.value) this.getData()
  },
  methods: {
    async getData () {
      const supplierProducts = await this.getSupplierProducts(this.value.id)
      supplierProducts.forEach(sp => {
        if (this.value.supplierProducts[sp.id]) {
          Object.assign(sp, this.value.supplierProducts[sp.id])
          sp.selected = true
        } else {
          sp.selected = false
          sp.availableQuantity = 0
        }
      })
      this.supplierProducts = supplierProducts
      this.selectedItems = supplierProducts.filter(sp => sp.selected)

      setTimeout(() => {
        for (const supplierProductId in this.value.supplierProducts) {
          delete this.value.supplierProducts[supplierProductId]
        }
      }, 300)
    },
    async getSupplierProducts (supplier) {
      try {
        const res = await this.$http.get('/v1/products/suppliers/' + supplier)
        return res.data
      } catch (err) {
      }
    },
    async saveSupplierProducts () {
      for (const sp of this.selectedItems) {
        this.value.supplierProducts[sp.id] = sp
      }

      this.show = false
      this.selectedItems = []
    },

    selectAllClick (inputFn, evt) {
      if (!this.editMode) inputFn(evt)
    },
    async addSuppProduct (sp) {
      if (!this.editMode) return

      await this.$http.post('/v1/panieri/' + this.paniereToEditId + '/supplier-products', {
        id: sp.id,
        price: sp.price,
        businessPrice: sp.businessPrice,
        supplierPrice: sp.supplierPrice,
        availableQuantity: sp.availableQuantity,
        weight: sp.weight
      })
    },
    async updateSuppProduct (sp) {
      if (!this.editMode) return

      const spToSend = {
        id: sp.id,
        price: sp.price,
        businessPrice: sp.businessPrice,
        supplierPrice: sp.supplierPrice,
        availableQuantity: sp.availableQuantity,
        weight: sp.weight
      }
      const { data } = await this.$http.put('/v1/panieri/' + this.paniereToEditId + '/supplier-products', spToSend)

      if (data.usersArePurchasing) {
        await this.$dialog.warning({
          title: 'Attenzione!',
          text: `Alcuni utenti stanno acquistando il prodotto <b>${sp.productName}</b>. Sei sicuro di volerlo modificare?<br>
                    <i class="text-caption">Verrà comunque inviata una mail di avviso agli utenti che lo stanno acquistando se è stato modificato il prezzo.</i>`,
          actions: [
            {
              text: 'Annulla',
              handler: async () => {
                sp.selected = true
                this.selectedItems.push(sp)
                const { data } = await this.$http.get(`/v1/panieri/${this.paniereToEditId}/supplier-products/${sp.id}`)
                Object.assign(sp, data)
              }
            },
            {
              text: 'Modifica',
              color: 'warning',
              outlined: true,
              handler: async () => {
                console.log('confirm update')
                await this.$http.put(`/v1/panieri/${this.paniereToEditId}/supplier-products/confirm`, spToSend)
                this.$dialog.notify.success('Modifiche salvate con successo').then()
              }
            }
          ]
        })
      } else {
        await this.$dialog.notify.success('Modifiche salvate con successo')
      }
    },
    async removeSuppProduct (sp) {
      if (!this.editMode) return

      const { data } = await this.$http.delete(`/v1/panieri/${this.paniereToEditId}/supplier-products/${sp.id}`)

      if (data.usersArePurchasing) {
        await this.$dialog.warning({
          title: 'Attenzione!',
          text: `Alcuni utenti stanno acquistando il prodotto <b>${sp.productName}</b>. Sei sicuro di volerlo rimuovere dal paniere?<br>
                    <i class="text-caption">Verrà comunque inviata una mail di avviso agli utenti che lo stanno acquistando.</i>`,
          actions: [
            {
              text: 'Annulla',
              handler: () => {
                sp.selected = true
                this.selectedItems.push(sp)
              }
            },
            {
              text: 'Rimuovi',
              color: 'warning',
              outlined: true,
              handler: async () => {
                // console.log('confirm remove')
                await this.$http.delete(`/v1/panieri/${this.paniereToEditId}/supplier-products/${sp.id}/confirm`)
              }
            }
          ]
        })
      }
    },

    onItemSelected ({
      item,
      value
    }) {
      item.selected = value

      if (value) {
        this.addSuppProduct(item)
      } else {
        this.removeSuppProduct(item)
      }
    },

    onAllItemsSelected ({
      items,
      value
    }) {
      items.map(it => {
        it.selected = value
      })
    },
    showHelpMessage () {
      this.$dialog.info({
        title: 'Info ',
        text: 'Se la quantità è pari a 0, significa che le scorte sono illimitate!'
      })
    }
  }

}
</script>

<style scoped>

</style>
