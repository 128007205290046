<template>
  <div>
    <DataCard
        title-icon="fas fa-box"
        title="Storico panieri"
        :items="panieri"
        :headers="headers"
        no-data-text="Non ci sono panieri!"
        :action-modal-object.sync="actionModal.obj"
        row-pointer
        @click:row="rowClick"
    >
      <template v-slot:item.effectiveDeliveryDate="{ value }">
        {{ formatDateYearFirst(value) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn :to="'crea/' + item.id" text>
          Usa come template
        </v-btn>
      </template>
    </DataCard>

    <paniere-details-dialog v-model="paniereToShow"/>
  </div>
</template>

<script>
import DataCard from '@/components/Cards/DataCard'
import PaniereDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereDetailsDialog'

export default {
  name: 'Panieri',
  components: { PaniereDetailsDialog, DataCard },
  data () {
    return {
      panieri: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Data di consegna effettiva', value: 'effectiveDeliveryDate' },
        { text: '', value: 'actions' }
      ],
      actionModal: {
        show: false,
        obj: {},
        editMode: false
      },
      paniereToShow: null
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    paniereToShow (newVal, oldVal) {
      if (newVal === null) {
        this.init()
      }
    }
  },
  methods: {
    async init () {
      try {
        const res = await this.$http.get('v1/panieri/status/delivered')
        this.panieri = res.data
      } catch (e) {
      }
    },
    closeActionModal () {
      this.actionModal.show = false
      this.init()
    },
    rowClick (item) {
      this.$router.push('/amministrazione/panieri/' + item.id)
    },
    redirectToCreatePanierePage () {
      this.$router.push('/amministrazione/panieri/crea')
    }
  }
}
</script>

<style scoped>

</style>
