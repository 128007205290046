<template>
  <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      @close="close"
  >
    <v-card tile>
      <v-toolbar flat color="primary">
        <v-btn icon class="white--text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Prodotti di {{value.businessName}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="dialog" class="my-4">
        <div v-if="status === 'ordered'"
             class="mb-4 text-right"
        >
          <v-btn color="lighter" outlined @click="resentOrderViaEmailClick">Reinvia ordine via mail</v-btn>
        </div>
        <v-data-table
        :headers="headers"
        :items="value.products">

          <template v-slot:item.imageUrl="{item}">
            <v-img
                v-if="item.imageUrl"
                class="white--text rounded-lg"
                width="50px"
                :src="item.imageUrl.toString()"
            >
            </v-img>
            <p v-else>Nesssuna Immagine</p>
          </template>
          <template v-slot:item.price="{item}">
            {{ (item.price / 100).toFixed(2) }} €
          </template>
          <template v-slot:item.businessPrice="{item}">
            {{ (item.businessPrice / 100).toFixed(2) }} €
          </template>
          <template v-slot:item.supplierPrice="{item}">
            {{ (item.supplierPrice / 100).toFixed(2) }} €
          </template>
          <template v-slot:item.weight="{item}">
            <span v-if="item.weight">{{ item.weight }} Kg</span>
          </template>
          <template v-slot:item.additionalQuantity="{item, value}">
            <validation-provider
              rules="min_value:0"
              name="quantità aggiuntiva"
              v-slot="{ errors }"
              v-if="editQtyAndAvailability"
            >
              <v-text-field
                  v-model.number="item.additionalQuantity"
                  :error-messages="errors"
              />
            </validation-provider>
            <span v-else>{{ value }}</span>
          </template>
          <template v-slot:item.notAvailable="{item}">
            <v-simple-checkbox
                v-if="editQtyAndAvailability"
                v-model="item.notAvailable"
            />
            <span v-else>
              <v-icon v-if="item.notAvailable">mdi-close</v-icon>
              <v-icon v-else>mdi-check</v-icon>
              {{item.notAvailable ? 'Non disponibile' : 'Disponibile'}}
            </span>
          </template>

        </v-data-table>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>

  </v-dialog>
</template>

<script>
// TODO getter setter available
export default {
  name: 'PaniereSupplierDetailsDialog',
  props: {
    value: Object,
    status: String,
    paniereId: Number
  },
  computed: {
    dialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    },
    editQtyAndAvailability () {
      return this.status === 'closed' || this.status === 'ordered'
    },
    headers () {
      return [
        // {
        //   text: '',
        //   value: 'imageUrl'
        // },
        {
          text: 'Nome',
          value: 'name'
        },
        {
          text: 'Quantità disponibile',
          value: 'availableQuantity'
        },
        {
          text: 'Prezzo di vendita',
          value: 'price'
        },
        {
          text: 'Prezzo business',
          value: 'businessPrice'
        },
        {
          text: 'Prezzo fornitore',
          value: 'supplierPrice'
        },
        {
          text: 'Peso',
          value: 'weight'
        },
        {
          text: 'Acquisti totali',
          value: 'totalPurchased'
        },
        { text: 'Quantità aggiuntiva', value: 'additionalQuantity' },
        { text: 'Disponibilità', value: 'notAvailable' }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('input', null)
      if (this.editQtyAndAvailability)
        this.updateAdditionalQuantityAvailability()
    },
    async updateAdditionalQuantityAvailability () {
      const products = this.value.products.map(({ id, additionalQuantity, notAvailable }) => ({
        id,
        additionalQuantity,
        notAvailable
      }))

      await this.$http.patch('v1/panieri/set/additional-quantity-availability', products)
    },
    async resentOrderViaEmailClick () {
      const qtyUpdate = this.updateAdditionalQuantityAvailability()

      const email = await this.$dialog.prompt({
        title: 'Reinvia ordine via mail',
        text: 'Indirizzo mail a cui inviare la mail',
        textField: {
          outlined: true
        },
        value: this.value.email,
        actions: [
          { text: 'Annulla', key: false },
          {
            text: 'Invia',
            color: 'success',
            outlined: true,
            key: true
          }
        ]
      })

      if (!email) return

      await qtyUpdate
      await this.$http.patch(`v1/panieri/${this.paniereId}/order/${this.value.id}/resend`, {
        email
      })

      this.$dialog.notify.success('Ordine inviato con successo').then()
    }
  }
}
</script>

<style scoped>

</style>
