<template>
  <v-card class="ma-6 grey lighten-4 rounded-lg" elevation="0">
    <div class="d-flex flex-wrap align-center w-100 pt-2 px-2">
      <v-card-title class="text-no-wrap text-h4 text-md-h3">
        <v-icon v-if="titleIcon" class="mr-3" large>{{ titleIcon }}</v-icon>
        {{ title }}
      </v-card-title>
      <v-btn
          v-if="createOption"
          class="ml-auto mr-8"
          color="green"
          dark
          @click="create"
      >
        Crea
      </v-btn>
      <div class="ml-auto mr-8">
        <slot name="actionButton" v-if="$scopedSlots.actionButton && !createOption"></slot>
      </div>
    </div>
    <v-card-text>
      <slot name="card-top"></slot>
      <div v-if="searchLabel" class="d-flex align-center mb-4 mx-0 elevation-0">
        <v-text-field
            v-model="search"
            append-icon="fa-search"
            solo
            :label="searchLabel"
            single-line
            hide-details
        />
      </div>
      <v-data-table
          class="rounded-lg"
          :class="{ 'row-pointer': rowPointer }"
          v-model="selectedItems"
          :items="items"
          :headers="headers"
          :search="search"
          :item-key="itemKey"
          :no-data-text="noDataText"
          :show-select="showSelect"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @click:row="(item) => {$emit('click:row', item)}"
      >

        <template v-if="operations" v-slot:item.crud="{ item }">

          <slot name="operations" v-bind:item="item"/>

          <v-btn
              v-if="updateOption"
              color="warning"
              class="pa-5"
              small icon
              @click.stop="edit(item)"
          >
            <v-icon small>fa-edit</v-icon>
          </v-btn>
          <v-btn
              v-if="deleteCallback"
              color="red"
              class="pa-5"
              small icon
              @click.stop="deleteCallback(item)"
          >
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </template>

        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>


</style>

<script>
export default {
  name: 'DataCard',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    titleIcon: String,
    searchLabel: String,
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      default: 'id'
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true
    },
    actionModal: {
      type: Boolean,
      required: false
    },
    actionModalEditMode: {
      type: Boolean,
      required: false
    },
    actionModalObject: {
      type: Object,
      required: false
    },
    createCallback: {
      type: Function,
      required: false
    },
    noDataText: {
      type: String,
      default: () => 'Nessun dato ancora presente!'
    },
    operations: { // operations are: create - update
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: () => false
    },
    deleteCallback: Function, // if no delete callback is set the delete button is not shown
    updateCallback: Function,
    rowPointer: Boolean

  },
  data () {
    return {
      search: '',
      searchQueryUpdateTimeout: null
    }
  },
  mounted () {
    this.search = this.$route.query.search
  },
  computed: {
    createOption () {
      return this.operations.includes('create')
    },
    updateOption () {
      return this.operations.includes('update')
    },
    selectedItems: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    search (newValue) {
      if (!this.searchQueryUpdateTimeout) {
        this.searchQueryUpdateTimeout = setTimeout(() => {
          this.searchQueryUpdateTimeout = null
          setTimeout(() => {
            this.$router.push({ query: { search: this.search } })
          }, 50)
        }, 1000)
      }
    }
  },
  methods: {
    create () {
      if (this.createCallback) {
        this.createCallback()
      } else {
        this.$emit('update:actionModalEditMode', false)
        this.$emit('update:actionModalObject', {})
        this.$emit('update:actionModal', true)
      }
    },
    edit (item) {
      if (this.updateCallback) {
        this.updateCallback(item)
      } else {
        this.$emit('update:actionModalEditMode', true)
        this.$emit('update:actionModalObject', { ...item })
        this.$emit('update:actionModal', true)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
