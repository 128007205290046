<template>
  <div>
    <common-details-dialog
        v-model="dialog"
        v-if="dialog"
        title="Informazioni paniere"
        :data-to-show="dataToShow"
    >
      <template v-slot:actions>
        <div class="text-center mx-10 mb-2">
          <v-btn v-if="value.status === 'opened'"
                 :disabled="!enabled"
                 @click="closePaniere"
                 outlined
                 color="warning"
                 class="mt-5"
          >Chiudi paniere</v-btn>
          <v-btn v-if="value.status === 'closed'"
                 :disabled="!enabled"
                 @click="order"
                 outlined
                 color="warning"
                 class="mt-5"
          >Ordina</v-btn>
        </div>
      </template>
      <template v-slot:data-text.suppliers>
        <v-list>
          <v-list-item-group
          >
            <v-list-item
                v-for="(item, id) in value.suppliers"
                :key="id"
                @click="clickSupplierRow(item)"
            >
              <template>
              </template>
              <v-list-item-content>
                <v-list-item-title v-text="item.businessName"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-slot:data-container.users>
        <DataCard
            title="Ordini del paniere"
            :items="value.orders"
            item-key="id.userId + id.paniereId"
            :headers="ordersDataCardHeaders"
            no-data-text="Non ci sono ordini per questo paniere!"
            row-pointer
            search-label="Trova un ordine"
            @click:row="clickOrderRow"
        ></DataCard>
      </template>
    </common-details-dialog>

    <paniere-supplier-details-dialog v-if="value" :status="value.status" :paniere-id="value.id" v-model="supplierToShow"/>

    <paniere-order-details-dialog v-if="value" v-model="orderToShow"/>
  </div>
</template>

<script>
import PaniereSupplierDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereSupplierDetailsDialog'
import CommonDetailsDialog from '@/components/CommonDetailsDialog/CommonDetailsDialog'
import DataCard from '@/components/Cards/DataCard'
import PaniereOrderDetailsDialog from '@/components/DetailsDialog/PaniereDetailsDialog/PaniereOrderDetailsDialog'

export default {
  name: 'PaniereDetailsDialog',
  components: {
    PaniereOrderDetailsDialog,
    CommonDetailsDialog,
    PaniereSupplierDetailsDialog,
    DataCard
  },
  props: {
    value: Object
  },
  data () {
    return {
      supplierToShow: null,
      enabled: true,
      ordersDataCardHeaders: [
        { text: 'Nome', value: 'user.name' },
        { text: 'Email', value: 'user.email' },
        { text: 'Numero di telefono', value: 'user.phoneNumber' },
        { text: 'Zona', value: 'deliveryZone.name' },
        { text: 'Nº prodotti', value: 'orderedProducts' }
      ],
      orderToShow: null
    }
  },
  computed: {
    dialog: {
      get () {
        return !!this.value
      },
      set (newVal) {
        if (!newVal) this.$emit('input', null)
      }
    },
    dataToShow () {
      return [
        {
          title: 'Dati del paniere',
          information: [
            {
              name: 'Nome',
              value: this.value.name
            },
            {
              name: 'Data apertura',
              value: this.formatDate(this.value.openDate)
            },
            {
              name: 'Data chiusura',
              value: this.formatDate(this.value.deliveryDate)
            },
            {
              name: 'Stato',
              value: this.value.status === 'opened' ? 'Aperto' : (this.value.status === 'closed' ? 'Chiuso' : 'Ordinato')
            }
          ]
        },
        {
          title: 'Fornitori',
          slotName: 'suppliers'
        },
        {
          title: 'Ordini del paniere',
          slotName: 'users'
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('input', null)
    },
    async clickSupplierRow (supplier) {
      const response = await this.$http.get(`v1/panieri/${this.value.id}/${supplier.id}`)
      this.supplierToShow = response.data
    },
    async closePaniere () {
      this.enabled = false
      try {
        await this.$dialog.confirmation.deletion(`Sei sicuro di voler chiudere il paniere <b>${this.value.name}</b>?`, 'Chiudi')
        await this.$http.patch(`v1/panieri/${this.value.id}/close`)
        this.value.status = 'closed'
        this.enabled = true
      } catch (e) {
        this.enabled = true
      }
    },
    async order () {
      this.enabled = false
      try {
        await this.$dialog.confirmation.deletion('Sei sicuro do voler ordinare ai fornitori?', 'Ordina')
        await this.$http.patch(`v1/panieri/${this.value.id}/order`)
        this.value.status = 'ordered'
        this.enabled = true
      } catch (e) {
        this.enabled = true
      }
    },
    async clickOrderRow (order) {
      const res = await this.$http.get(`v1/orders/${order.id.userId}/${order.id.paniereId}`)
      this.orderToShow = res.data
    }
  }
}
</script>

<style scoped>
</style>
